var DomainConnectionStatus;
(function (DomainConnectionStatus) {
  DomainConnectionStatus["CONNECTED"] = "connected";
  DomainConnectionStatus["NOT_CONNECTED"] = "notConnected";
  DomainConnectionStatus["MANUALLY_MARKED"] = "manuallyMarked";
  DomainConnectionStatus["REVERSE_PROXY"] = "reverseProxy";
  DomainConnectionStatus["UNKNOWN"] = "unknown";
  DomainConnectionStatus["IP_WARMUP"] = "ipWarmUp";
  DomainConnectionStatus["PROCESSING"] = "processing";
  DomainConnectionStatus["DNS_MISCONFIGURATION"] = "dnsMisconfiguration";
})(DomainConnectionStatus || (DomainConnectionStatus = {}));
export default DomainConnectionStatus;