import { createSelector } from 'reselect';
import { getPortalHasDedicatedMarketingScope, getPortalHasDedicatedTransactionalScope } from './Auth';
import { DedicatedIPAccountTypes, DedicatedIPWizardAccountTypes } from '../enums/DedicatedIP';
import { ListingState } from '../Constants';
import { anyRequestsFailed, anyRequestsPending } from 'settings-ui-lib/utils/requestStatusChecks';
import { getIsStatusSetUp, getSmtpDomain } from '../utils/domains/dedicatedIP';
// @ts-expect-error not migrated to TS yet
import { getRootDomainName } from '../utils/domains/Domains';
import RequestStatusTypes from 'settings-ui-lib/constants/RequestStatusTypes';
const getDedicatedIPv2 = state => state.dedicatedIPv2;

// Enable / disable behavior
const getDedicatedIPNeedsAccountSetup = createSelector([getDedicatedIPv2], dedicatedIPv2 => dedicatedIPv2.needsAccountSetup);
export const getNeedsAccountSetupRequestStatus = createSelector([getDedicatedIPNeedsAccountSetup], needsAccountSetup => needsAccountSetup.requestStatus);
export const getIsPortalSandbox = createSelector([getDedicatedIPNeedsAccountSetup], needsAccountSetup => !!needsAccountSetup.isSandbox);
export const getPortalCanSetupMoreMarketingIPs = createSelector([getDedicatedIPNeedsAccountSetup], needsAccountSetup => !!needsAccountSetup.canSetupMoreMarketingIPs);
export const getPortalCanSetupMoreTransactionalIPs = createSelector([getDedicatedIPNeedsAccountSetup], needsAccountSetup => !!needsAccountSetup.canSetupMoreTransactionalIPs);
export const getCanSetupMoreDedicatedIPs = createSelector([getPortalHasDedicatedMarketingScope, getPortalHasDedicatedTransactionalScope, getPortalCanSetupMoreMarketingIPs, getPortalCanSetupMoreTransactionalIPs], (portalHasMarketingScope, portalHasTransactionalScope, canSetupMoreMarketing, canSetupMoreTransactional) => portalHasMarketingScope && canSetupMoreMarketing || portalHasTransactionalScope && canSetupMoreTransactional);

// Dedicated IP account setup statuses
const getDedicatedMarketingState = createSelector([getDedicatedIPv2], dedicatedIPv2 => dedicatedIPv2[DedicatedIPAccountTypes.DEDICATED_MARKETING]);
const getDedicatedTransactionalState = createSelector([getDedicatedIPv2], dedicatedIPv2 => dedicatedIPv2[DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL]);
export const getDedicatedMarketingRequestStatus = createSelector([getDedicatedMarketingState], marketingState => marketingState.requestStatus);
export const getDedicatedTransactionalRequestStatus = createSelector([getDedicatedTransactionalState], transactionalState => transactionalState.requestStatus);
export const getDedicatedIPStatusMapByAccountType = accountType => createSelector([getDedicatedIPv2], dedicatedIPv2 => dedicatedIPv2[accountType].statusMap);
export const getDedicatedMarketingStatusMap = createSelector([getDedicatedMarketingState], marketingState => marketingState.statusMap);
export const getDedicatedTransactionalStatusMap = createSelector([getDedicatedTransactionalState], transactionalState => transactionalState.statusMap);
export const getDedicatedMarketingStatusList = createSelector([getDedicatedMarketingState], marketingState => marketingState.statusList);
export const getDedicatedTransactionalStatusList = createSelector([getDedicatedTransactionalState], transactionalState => transactionalState.statusList);

// Shared dedicated IP action modal request status state
const getDedicatedIPModalState = createSelector([getDedicatedIPv2], dedicatedIPv2 => dedicatedIPv2.modal);
const getDedicatedIPModalRequestStatusMap = createSelector([getDedicatedIPModalState], modalState => modalState.requestStatusMap);
export const getDedicatedIPModalUpdatedSmtpDomain = createSelector([getDedicatedIPModalState], modalState => modalState.updatedSmtpDomain);
export const getDedicatedIPModalAccountType = createSelector([getDedicatedIPModalState], modalState => modalState.accountType);
export const getDedicatedIPModalError = createSelector([getDedicatedIPModalState], modalState => modalState.error);
export const getDedicatedIPModalAction = createSelector([getDedicatedIPModalState], modalState => modalState.modalAction);
export const getDedicatedIPModalRequestStatus = expectedModalAction => createSelector([getDedicatedIPModalRequestStatusMap, getDedicatedIPModalUpdatedSmtpDomain, getDedicatedIPModalAction], (requestStatusMap, updatedSmtpDomain, modalAction) => {
  const isExpectedModalAction = expectedModalAction === modalAction;
  const isValidSmtpDomain = updatedSmtpDomain && updatedSmtpDomain in requestStatusMap;
  return isExpectedModalAction && isValidSmtpDomain ? requestStatusMap[updatedSmtpDomain] : RequestStatusTypes.UNINITIALIZED;
});

// Business unit assignment state
export const getBusinessUnitToSmtpDomainMapByAccountType = accountType => createSelector([getDedicatedIPv2], dedicatedIPv2 => dedicatedIPv2[accountType].businessUnitToSmtpDomainMap);
export const getSmtpDomainToBusinessUnitsMapByAccountType = accountType => createSelector([getDedicatedIPv2], dedicatedIPv2 => dedicatedIPv2[accountType].smtpDomainToBusinessUnitsMap);
export const getAssignedBusinessUnitsCountByAccountType = accountType => createSelector([getBusinessUnitToSmtpDomainMapByAccountType(accountType)], businessUnitToSmtpDomainMap => Object.keys(businessUnitToSmtpDomainMap).length);
export const getDedicatedIPDefaultSmtpDomainByAccountType = accountType => createSelector([getDedicatedIPv2], dedicatedIPv2 => dedicatedIPv2[accountType].defaultSmtpDomain);

// For DomainListing shared loading spinner state
export const getDedicatedIPListingState = createSelector([getDedicatedMarketingRequestStatus, getDedicatedTransactionalRequestStatus, getDedicatedMarketingStatusList, getDedicatedTransactionalStatusList, getPortalHasDedicatedMarketingScope, getPortalHasDedicatedTransactionalScope], (marketingRequestStatus, transactionalRequestStatus, marketingStatusList, transactonalStatusList, portalHasDedicatedMarketingScope, portalHasDedicatedTransactionalScope) => {
  if (anyRequestsPending(marketingRequestStatus, transactionalRequestStatus)) {
    return ListingState.LOADING;
  }
  if (anyRequestsFailed(marketingRequestStatus, transactionalRequestStatus)) {
    return ListingState.ERROR;
  }
  if (portalHasDedicatedMarketingScope && marketingStatusList.length || portalHasDedicatedTransactionalScope && transactonalStatusList.length) {
    return ListingState.RETRIEVED;
  }
  return ListingState.EMPTY;
});

// For continue banners / setup notifications

export const getIncompleteSetupDedicatedIPs = createSelector([getDedicatedMarketingStatusMap, getDedicatedTransactionalStatusMap, getDedicatedMarketingStatusList, getDedicatedTransactionalStatusList], (marketingStatusMap, transactionalStatusMap, marketingStatusList, transactionalStatusList) => {
  const incompleteSetupDedicatedIPs = [];
  marketingStatusList.forEach(status => {
    if (!getIsStatusSetUp(status)) {
      const domainName = getRootDomainName(false, status.smtpDomain);
      const possibleTransactionalSmtpDomain = getSmtpDomain({
        accountType: DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL,
        domainName
      });
      if (transactionalStatusMap[possibleTransactionalSmtpDomain]) {
        incompleteSetupDedicatedIPs.push({
          domainName,
          dedicatedIPSetupAccountType: DedicatedIPWizardAccountTypes.BOTH
        });
      } else {
        incompleteSetupDedicatedIPs.push({
          domainName,
          dedicatedIPSetupAccountType: DedicatedIPWizardAccountTypes.DEDICATED_MARKETING
        });
      }
    }
  });
  transactionalStatusList.forEach(status => {
    if (!getIsStatusSetUp(status)) {
      const domainName = getRootDomainName(false, status.smtpDomain);
      const possibleMarketingSmtpDomain = getSmtpDomain({
        accountType: DedicatedIPAccountTypes.DEDICATED_MARKETING,
        domainName
      });
      if (!marketingStatusMap[possibleMarketingSmtpDomain]) {
        incompleteSetupDedicatedIPs.push({
          domainName,
          dedicatedIPSetupAccountType: DedicatedIPWizardAccountTypes.DEDICATED_TRANSACTIONAL
        });
      }
    }
  });
  return incompleteSetupDedicatedIPs;
});